<template>
	<div class="Employee_Information_add" v-loading="loading">
		<div class="top_title">
			入职登记
			<div class="title_btns" @click="handel_sd" v-if="$route.query.id&&user_info.is_super">{{ form.is_lock? '已锁定':'已解锁 ' }}</div>
		</div>
		<div class="form_flex">
			<div class="form_item ">
				<div class="titles"><span>*</span>姓名</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入姓名" v-model="form.name" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>手机号</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入手机号" v-model="form.mobile" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>员工号</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入员工号" v-model="form.gonghao" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>曾用名</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入曾用名" v-model="form.real_name" :disabled="form.is_lock==1">
				</div>
			</div>

			<div class="form_item ">
				<div class="titles"><span>*</span>证件类型及证件号</div>
				<div class="inputs2">
					<div class="check1">
						<el-select v-model="form.id_type" filterable placeholder="请选择" :disabled="form.is_lock==1">
							<el-option v-for="item in zhlx_list" :key="item.value" :label="item.title" :value="item.title">
							</el-option>
						</el-select>
					</div>
					<div class="check2">
						<input type="text" v-model="form.id_card" placeholder="请输入身份证号" :disabled="form.is_lock==1">
					</div>
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>身份证有效期</div>
				<div class="inputs3">
					<el-date-picker v-model="form.id_card_time" type="date" placeholder="请选择身份证有效期" format="yyyy-MM-dd" :disabled="form.is_lock==1"
						value-format="yyyy-MM-dd">
					</el-date-picker>
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>性别</div>
				<div class="inputs4">
					<el-radio v-model="form.sex" label="男" :disabled="form.is_lock==1">男</el-radio>
					<el-radio v-model="form.sex" label="女" :disabled="form.is_lock==1">女</el-radio>
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>出生日期(档案)</div>
				<div class="inputs3">
					<el-date-picker v-model="form.birthday2" type="date" placeholder="请选择出生日期" format="yyyy-MM-dd" :disabled="form.is_lock==1"
						value-format="yyyy-MM-dd" :picker-options="pickerOptions_csrq">
					</el-date-picker>
				</div>
			</div>

			<div class="form_item ">
				<div class="titles"><span>*</span>民族</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入民族" v-model="form.nation" :disabled="form.is_lock==1">
				</div>
			</div>

			<!-- <div class="form_item ">
				<div class="titles"><span>*</span>出生日期</div>
				<div class="inputs3">
					<el-date-picker v-model="form.birthday" type="date" placeholder="请选择出生日期(档案)" format="yyyy-MM-dd"
						value-format="yyyy-MM-dd" :picker-options="pickerOptions_csrq">
					</el-date-picker>
				</div>
			</div> -->
			<div class="form_item ">
				<div class="titles"><span>*</span>出生地</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入出生地" v-model="form.address" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>籍贯</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入籍贯" v-model="form.native" :disabled="form.is_lock==1">
				</div>
			</div>

			<div class="form_item form_bg">
				<div class="titles"><span>*</span>户口性质</div>
				<div class="inputs5">
					<el-select v-model="form.hkxz" filterable placeholder="请选择" :disabled="form.is_lock==1">
						<el-option v-for="item in hkxz_list" :key="item.value" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>户口所在地</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入户口所在地" v-model="form.hk_address" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>进入集团时间</div>
				<div class="inputs3">
					<el-date-picker v-model="form.jrjt_time" type="date" placeholder="请选择进入集团时间" format="yyyy-MM-dd" :disabled="form.is_lock==1"
						value-format="yyyy-MM-dd" :picker-options="pickerOptions_jrjtsj">
					</el-date-picker>
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>国籍或地区</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入国籍或地区" v-model="form.guoji" :disabled="form.is_lock==1">
				</div>
			</div>

			<div class="form_item form_bg">
				<div class="titles"><span>*</span>健康状况</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入健康状况" v-model="form.jiankang" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>进入本企业时间</div>
				<div class="inputs3">
					<el-date-picker v-model="form.jrqy_time" type="date" placeholder="请选择进入本企业时间" format="yyyy-MM-dd"
						value-format="yyyy-MM-dd" :picker-options="pickerOptions_jrqysj" :disabled="form.is_lock==1">
					</el-date-picker>
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"> 人员身份</div>
				<div class="inputs5">
					<el-select v-model="form.shenfen" filterable placeholder="请选择" :disabled="form.is_lock==1">
						<el-option v-for="item in rysf_list" :key="item.value" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>婚姻状况</div>
				<div class="inputs5">
					<el-select v-model="form.hyzt" filterable placeholder="请选择" :disabled="form.is_lock==1">
						<el-option v-for="item in hyzt_list" :key="item.value" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
			</div>

			<div class="form_item form_bg">
				<div class="titles"><span>*</span>工作地点</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入工作地点" v-model="form.gz_address" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>归侨侨眷标识</div>
				<div class="inputs4">
					<el-radio v-model="form.gqqjbs" label="是" :disabled="form.is_lock==1">是</el-radio>
					<el-radio v-model="form.gqqjbs" label="否" :disabled="form.is_lock==1">否</el-radio>
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>复转军人标识</div>
				<div class="inputs4">
					<el-radio v-model="form.fzjrbs" label="是" :disabled="form.is_lock==1">是</el-radio>
					<el-radio v-model="form.fzjrbs" label="否" :disabled="form.is_lock==1">否</el-radio>
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>职业危害/特殊工种</div>
				<div class="inputs5">
					<el-select v-model="form.zywh" filterable placeholder="请选择" :disabled="form.is_lock==1">
						<el-option v-for="item in zywh_list" :key="item.title" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
			</div>

			<div class="form_item form_bg">
				<div class="titles"><span>*</span>两代表一委员经历</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入两代表一委员经历" v-model="form.ldbwy" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>熟悉专业有何专长</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入熟悉专业有何专长" v-model="form.zyzc" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"> 社会工龄扣除（月）</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入社会工龄扣除（月）" v-model="form.shgl" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>参加工作时间</div>
				<div class="inputs3">
					<el-date-picker v-model="form.gz_time" type="date" placeholder="请选择参加工作时间" format="yyyy-MM-dd" :disabled="form.is_lock==1"
						value-format="yyyy-MM-dd" :picker-options="pickerOptions_cjgzsj">
					</el-date-picker>
				</div>
			</div>

			<div class="form_item form_bg">
				<div class="titles"><span>*</span>转正时间</div>
				<div class="inputs3">
					<el-date-picker v-model="form.zz_time" type="date" placeholder="请选择转正时间" format="yyyy-MM-dd" :disabled="form.is_lock==1"
						value-format="yyyy-MM-dd">
					</el-date-picker>
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"> 集团工龄扣除（月）</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入集团工龄扣除（月）" v-model="form.jtglkcy" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>任职生效时间</div>
				<div class="inputs3">
					<el-date-picker v-model="form.rzyx_time" type="date" placeholder="请选择任职生效时间" format="yyyy-MM-dd" :disabled="form.is_lock==1"
						value-format="yyyy-MM-dd">
					</el-date-picker>
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>进入集团人员来源</div>
				<div class="inputs5">
					<el-select v-model="form.jrjtryly" filterable placeholder="请选择" :disabled="form.is_lock==1">
						<el-option v-for="item in jrjtryly_list" :key="item.title" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
			</div>

			<div class="form_item form_bg">
				<div class="titles"><span>*</span>所属部门</div>
				<div class="inputs5">
					<bumen_cascader ref="bumen_cascader" :is_show_position="false" @confirm="confirm_bumen_cascader_value" />
				</div>
			</div>

			<div class="form_item ">
				<div class="titles"><span>*</span>岗位</div>
				<div class="inputs5">
					<el-select v-model="form.level6" filterable placeholder="请选择" @change="change6"  :disabled="form.is_lock==1">
						<el-option v-for="item in level6_list" :key="item.title" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
			</div>



			<div class="form_item ">
				<div class="titles"><span>*</span>职级_序列名称</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入职级_序列名称" v-model="form.zjxlmc"  :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles">职级_序列职级方案</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入职级_序列职级方案" v-model="form.zjxlfa"  :disabled="form.is_lock==1">
				</div>
			</div>

			<div class="form_item form_bg">
				<div class="titles"><span>*</span>用工类型</div>
				<div class="inputs5">
					<el-select v-model="form.yglx" filterable placeholder="请选择" :disabled="form.is_lock==1">
						<el-option v-for="item in yglx_list" :key="item.value" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>职级_职级名称</div>
				<div class="inputs5">
					<el-select v-model="form.zjmc" filterable placeholder="请选择" :disabled="form.is_lock==1">
						<el-option v-for="item in zjmc_list" :key="item.title" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
				<!-- <div class="inputs1">
                    <input type="text" placeholder="请输入职级_职级名称" v-model="form.zjmc">
                </div> -->
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>职工类型</div>
				<div class="inputs5">
					<el-select v-model="form.zglx" filterable placeholder="请选择" :disabled="form.is_lock==1">
						<el-option v-for="item in zglx_list" :key="item.value" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>变动事务</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入变动事务" v-model="form.bdsw" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>岗位状态</div>
				<div class="inputs5">
					<el-select v-model="form.gwzt" filterable placeholder="请选择" :disabled="form.is_lock==1">
						<el-option v-for="item in gwzt_list" :key="item.value" :label="item.title" :value="item.value">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"> 排序码</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入排序码" v-model="form.sort" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>变动原因</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入排序码" v-model="form.bdyy" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"> 工作开始时间</div>
				<div class="inputs3">
					<el-date-picker v-model="form.work_start_at" type="date" placeholder="请选择工作开始时间" format="yyyy-MM-dd" :disabled="form.is_lock==1"
						value-format="yyyy-MM-dd">
					</el-date-picker>
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"> 学历</div>
				<div class="inputs5">
					<el-select v-model="form.edu" filterable placeholder="请选择" :disabled="form.is_lock==1">
						<el-option v-for="item in edu_list" :key="item.value" :label="item.title" :value="item.title">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="form_item">
				<div class="titles"> 奖金卡银行</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入奖金卡银行" v-model="form.jjkh_bank" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item">
				<div class="titles"> 奖金卡号</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入奖金卡号" v-model="form.jjkh" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"> 工资卡银行</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入工资卡银行" v-model="form.gzkh_bank" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"> 工资卡号</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入工资卡号" v-model="form.gzkh" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"> 应聘工资</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入应聘工资" v-model="form.apply_gz" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"> 学历工资</div>
				<div class="inputs1">
					<input type="text" placeholder="请输入学历工资" v-model="form.edu_gz" :disabled="form.is_lock==1">
				</div>
			</div>
			<div class="flex_btns " v-if="!form.is_lock">

				<div class="btns1" @click="handle_end()">提交</div>
				<div class="btns2" @click="$router.push('/Employee_Information')">取消</div>
			</div>

		</div>
	</div>
</template>
<script>
	export default {
		name: "Customer_Information_form",

		data() {
			return {
				bumen_position_info: {
					bumen_ids: '', //部门id
					bumen_titles: '', //部门标题
				},
				user_info:{},
				ge_deep: 6,

				loading: false,
				is_gw: false, //判断是否到了岗位
				form: {
					name: '', //姓名
					mobile: '', //手机号
					real_name: '', //曾用名
					sex: '', //性别
					birthday: '', //生日
					birthday2: '', //生日(档案)
					nation: '', //民族
					native: '', //籍贯
					address: '', //出生地
					id_type: '', //证件类型
					id_card: '', //身份证号
					id_card_time: '', //身份证有效期
					gonghao: '', //员工号
					hkxz: '', //户口性质
					hk_address: '', //户口所在地
					jrjt_time: '', //进入集团时间
					guoji: '', //国籍或地区
					jiankang: '', //健康状态
					jrqy_time: '', //进入本企业时间
					shenfen: '', //人员身份
					hyzt: '', //婚姻状况
					gz_address: '', //工作地点
					gqqjbs: '', //归侨侨眷标识
					fzjrbs: '', //复转军人标识
					zywh: '', //职业危害/特殊工种
					ldbwy: '', //两代表一委员经历
					zyzc: '', //熟悉专业有何专长
					shgl: '', //社会工龄扣除
					gz_time: '', //参加工作时间
					zz_time: '', //转正时间
					jtglkcy: '', //集团工龄扣除月
					rzyx_time: '', //任职生效时间
					jrjtryly: '', //进入集团人员来源


					level6: '', //职级6
					level6_id: '', //职级6id 岗位id
					zjxlmc: '', //职级序列名称
					zjxlfa: '', //职级序列职级方案
					yglx: '', //用工类型
					zjmc: '', //职级名称
					bdsw: '', //变动事物
					gwzt: '', //岗位状态
					sort: '', //排序
					bdyy: '', //变动原因
					work_start_at: '', //工作开始时间
					edu: '', //学历
					depart_title: '',
					lock:0,//0解锁  1锁定
					edu_gz:'',//学历工资
					apply_gz:'',//应聘工资
				},
				zhlx_list:  [],
				hkxz_list: [],
				rysf_list: [],
				hyzt_list: [
				],
				zywh_list: [ 
				],
				jrjtryly_list: [ 
				],
				zjmc_list: [

				],
				yglx_list: [ 
				],
				gwzt_list: [
					 
				],
				zglx_list:[
			 
				],
				edu_list: [ 
				],
				gw_list: [],
				level1_list: [],
				level2_list: [],
				level3_list: [],
				level4_list: [],
				level5_list: [],
				level6_list: [],
				//出生日期时间限制
				pickerOptions_csrq: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				},
				pickerOptions_jrjtsj: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				},
				pickerOptions_jrqysj: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				},
				pickerOptions_cjgzsj: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				},
			};
		},
		computed: {

		},
		watch: {

		},
		created() {
			this.get_gw_list()
			// this.get_zjmc_list()
			this.get_config()
			this.get_user()
		},
		methods: {
			//权限判断
			get_user() {
				// this.NavigateItem = []
				console.log(localStorage.getItem('cache_user'))
				this.loading = true
				let data={}
				if(localStorage.getItem('cache_user')){
					this.user_info=JSON.parse(localStorage.getItem('cache_user'))
				}
			 

			},
			handel_sd(){
				this.loading = true
				let lock=0
				if(this.form.is_lock){
					lock=0
				}else{
					lock=1
				}
				this.$api("lockUser", {
					ids:this.$route.query.id,
					lock:lock// 0解锁  1锁定
				}, "post").then((res) => {
					console.log('所有的岗位', res)
					this.loading = false
					if (res.code == 200) {
						this.get_detial()
					} else {
						alertErr(res.msg)
					}
				});
			},
			get_config() {
				this.zhlx_list = this.get_vuex_type(23);//证件类型及证件号
				this.hkxz_list = this.get_vuex_type(26);//户口类型
				this.rysf_list = this.get_vuex_type(14);//人员身份
				this.hyzt_list = this.get_vuex_type(279);//婚姻状况
				
				this.zywh_list = this.get_vuex_type(29);//职业危害
				this.jrjtryly_list = this.get_vuex_type(33);//人员来源
				

				this.yglx_list = this.get_vuex_type(11);//用工类型
				this.gwzt_list = this.get_vuex_type(2);//岗位状态

				this.zglx_list = this.get_vuex_type(86); //职工类型
				this.zjmc_list = this.get_vuex_type(12); //职级名称


				
				this.edu_list=this.get_vuex_type(42); //学历
			},
			handle_end() {
				if(this.user_info.is_super){
					if (!this.form.name) {
						alertErr('请输入姓名')
						return
					}
					if (!this.form.mobile) {
						alertErr('请输入手机号')
						return
					}

					if (!this.form.gonghao) {
						alertErr('请输入员工号')
						return
					}
					if (!this.bumen_position_info.bumen_ids) {
						alertErr('请选择所属部门')
						return
					}


					// if (!this.form.level6) {
					// 	alertErr('请选择岗位六级组织(岗位)')
					// 	return
					// }

					//六级组织(岗位)
					if (!this.form.level6) {
						alertErr('请选择岗位')
						return
					}
				}else{
						if (!this.form.real_name) {
						alertErr('请输入曾用名')
						return
					}

					if (!this.form.id_type) {
						alertErr('请选择证件类型')
						return
					}
					if (!this.form.id_card) {
						alertErr('请输入身份证号')
						return
					}
					if (!this.form.id_card_time) {
						alertErr('请选择身份证有效期')
						return
					}

					if (!this.form.sex) {
						alertErr('请选择性别')
						return
					}
					if (!this.form.birthday2) {
						alertErr('请选择出生日期(档案)')
						return
					}

					if (!this.form.nation) {
						alertErr('请输入民族')
						return
					}

					// if (!this.form.birthday) {
					// 	alertErr('请选择出生日期')
					// 	return
					// }

					if (!this.form.address) {
						alertErr('请输入出生地')
						return
					}
					if (!this.form.native) {
						alertErr('请输入籍贯')
						return
					}
					if (!this.form.hkxz) {
						alertErr('请选择户口性质')
						return
					}

					if (!this.form.hk_address) {
						alertErr('请输入户口所在地')
						return
					}
					if (!this.form.jrjt_time) {
						alertErr('请选择进入集团时间')
						return
					}
					if (!this.form.guoji) {
						alertErr('请输入国籍或地区')
						return
					}
					if (!this.form.jiankang) {
						alertErr('请输入健康状况')
						return
					}

					if (!this.form.jrqy_time) {
						alertErr('请选择进入本企业时间')
						return
					}
					// if (!this.form.shenfen) {
					// 	alertErr('请选择人员身份')
					// 	return
					// }
					if (!this.form.hyzt) {
						alertErr('请选择婚姻状况')
						return
					}
					if (!this.form.gz_address) {
						alertErr('请输入工作地点')
						return
					}
					if (!this.form.gqqjbs) {
						alertErr('请选择归侨侨眷标识')
						return
					}
					if (!this.form.fzjrbs) {
						alertErr('请选择复转军人标识')
						return
					}
					if (!this.form.zywh) {
						alertErr('请选择职业危害/特殊工种')
						return
					}
					if (!this.form.ldbwy) {
						alertErr('请输入两代表一委员经历')
						return
					}
					if (!this.form.zyzc) {
						alertErr('请输入熟悉专业有何专长')
						return
					}
					// if (!this.form.shgl) {
					// 	alertErr('请输入社会工龄扣除（月）')
					// 	return
					// }
					if (!this.form.gz_time) {
						alertErr('请选择参加工作时间')
						return
					}
					if (!this.form.zz_time) {
						alertErr('请选择转正时间')
						return
					}
					// if (!this.form.jtglkcy) {
					// 	alertErr('请输入集团工龄扣除（月）')
					// 	return
					// }
					if (!this.form.rzyx_time) {
						alertErr('请选择任职生效时间')
						return
					}
					if (!this.form.jrjtryly) {
						alertErr('请选择进入集团人员来源')
						return
					}



				


					if (!this.form.zjxlmc) {
						alertErr('请输入职级_序列名称')
						return
					}
					// if (!this.form.zjxlfa) {
					// 	alertErr('请输入职级_序列职级方案')
					// 	return
					// }
					if (!this.form.yglx) {
						alertErr('请选择用工类型')
						return
					}
					if (!this.form.zjmc) {
						alertErr('请选择职级_职级名称')
						return
					}
					if (!this.form.zglx) {
						alertErr('请输入职工类型')
						return
					}
					if (!this.form.bdsw) {
						alertErr('请输入变动事务')
						return
					}
					if (!this.form.gwzt) {
						alertErr('请选择岗位状态')
						return
					}

					// if (!this.form.sort) {
					// 	alertErr('请输入排序码')
					// 	return
					// }
					if (!this.form.bdyy) {
						alertErr('请输入变动原因')
						return
					}
					// if (!this.form.work_start_at) {
					// 	alertErr('请选择工作开始时间')
					// 	return
					// }
					// if (!this.form.edu) {
					// 	alertErr('请选择学历')
					// 	return
					// }
				}
				
				
				console.log('提交', this.form)
				// let depart_title = '';

				this.loading = true
				this.$api("editUser", {
					...this.form,
					depart_title: this.bumen_position_info.bumen_titles, //部门标题 使用 / 拼接
					depart_path_id: this.bumen_position_info.bumen_ids, // id 路径使用 / 拼接
				}, "post").then((res) => {
					console.log('所有的岗位', res)
					this.loading = false
					if (res.code == 200) {
						this.$router.replace({
							path: '/Employee_Information_add_success',
							query: {
								id: res.data.id
							}
						})

					} else {
						alertErr(res.msg)
					}
				});
			},
			//获取岗位
			get_gw_list() {
				this.loading = true
				this.$api("getCategoryAllList", {

				}, "get").then((res) => {
					console.log('所有的岗位', res)
					this.loading = false
					if (res.code == 200) {
						let level = 0
						let list = []
						let data = res.data
						data.forEach((e, i) => {
							if (e.level > level) {
								level = e.level
							}
							this.$set(e, 'children', [])
						})
						console.log('最高登记', level)

						for (let i = 1; i <= level; i++) {
							let level_list = []
							data.forEach((e) => {
								if (e.level == i) {
									level_list.push(e)
								}
							})
							list.push(level_list)
						}
						console.log('列表', list)
						// list[list.length-1].forEach((e)=>{
						//     this.$delete(e,'children')
						// })
						// 总列表  最后一位的下标
						this.gw_list_data(list, list.length - 1)
						if (this.$route.query.id) {
							this.get_detial()

						}


					} else {
						alertErr(res.msg)
					}
				});
			},
			//处理岗位列表
			gw_list_data(lists, indexs) {
				let index1 = indexs //当前下标
				let index2 = indexs - 1 //上级下标
				let list = lists
				list[index1].forEach((e) => {
					list[index2].forEach((v) => {
						if (v.id == e.parent_id) {
							v.children.push(e)
						}
					})
				})
				// list[index2].forEach((e)=>{
				//     if(e.children.length==0){
				//         this.$delete(e,'children')
				//     }
				// })
				if (index2 == 0) {
					console.log('最后的数据', list)
					this.gw_list = list[0]
					return
				}
				this.gw_list_data(list, index2)
			},
			//岗位选中处理
			change1(e, i) {
				console.log(e)
				this.gw_list.forEach((v) => {
					if (v.title == e) {
						console.log(v)
						this.level2_list = v.children
						if (i != -1) {
							this.form.level2 = ''
							this.form.level3 = ''
							this.form.level4 = ''
							this.form.level5 = ''
							this.form.level6 = ''
						}
						if (v.children.some(v => v.is_gw)) {
							this.ge_deep = 2;
							this.form.depart_id = v.id
							this.level6_list = v.children

						} else {
							this.ge_deep = 6
						}
					}
				})
			},
			change2(e, i) {
				console.log(e)
				this.level2_list.forEach((v) => {
					console.log(v)
					if (v.title == e) {
						console.log(v)
						this.level3_list = v.children
						if (i != -1) {
							this.form.level3 = ''
							this.form.level4 = ''
							this.form.level5 = ''
							this.form.level6 = ''
						}
						if (v.children.some(v => v.is_gw)) {
							this.ge_deep = 3;
							this.level6_list = v.children
							this.form.depart_id = v.id

						} else {
							this.ge_deep = 6
						}
					}
				})
			},
			change3(e, i) {
				console.log(e)
				this.level3_list.forEach((v) => {
					if (v.title == e) {
						console.log(v)
						this.level4_list = v.children
						if (i != -1) {
							this.form.level4 = ''
							this.form.level5 = ''
							this.form.level6 = ''
						}
						if (v.children.some(v => v.is_gw)) {
							this.ge_deep = 4;
							this.level6_list = v.children
							this.form.depart_id = v.id
						} else {
							this.ge_deep = 6
						}
					}
				})
			},
			change4(e, i) {
				console.log(e)
				this.level4_list.forEach((v) => {
					if (v.title == e) {
						console.log(v)
						this.level5_list = v.children
						if (i != -1) {
							this.form.level5 = ''
							this.form.level6 = ''
						}


						if (v.children.some(v => v.is_gw)) {
							this.ge_deep = 5;
							this.level6_list = v.children
							this.form.depart_id = v.id

						} else {
							this.ge_deep = 6
						}
					}
				})
			},
			change5(e, i) {
				console.log(e)
				this.level5_list.forEach((v) => {
					if (v.title == e) {
						console.log(v)
						this.level6_list = v.children
						this.form.level6 = ''
						if (v.children.some(v => v.is_gw)) {
							this.ge_deep = 6;
							this.level6_list = v.children
							this.form.depart_id = v.id

						} else {
							this.ge_deep = 6
						}
					}
				})
			},
			change6(e) {
				console.log(e)
				this.level6_list.forEach((v) => {
					if (v.title == e) {
						console.log(v)
						this.form.level6_id = v.id
					}
				})
			},

			get_detial() {
				this.loading = true
				this.$api("getUser", {
					id: this.$route.query.id
				}, "get").then((res) => {
					console.log('详情', res)
					this.loading = false
					if (res.code == 200) {
						this.form = res.data

						if (res.data.depart_path_id) {
							this.bumen_position_info.bumen_ids=res.data.depart_path_id
							let id_arr = res.data.depart_path_id.split('/').map(id => parseInt(id))
							console.log('回显 id_arr',id_arr)
							this.$nextTick(() => {
								this.$refs.bumen_cascader.init({
									type: '设置数据',
									value: id_arr,
								})
							})


						}



					} else {
						alertErr(res.msg)
					}
				});
			},
			get_zjmc_list() {
				this.loading = true
				this.$api("enumsetting", {
					flag: '职级'
				}, "post").then((res) => {
					console.log('所有职位', res)
					this.loading = false
					if (res.code == 200) {
						this.zjmc_list = res.data

					} else {
						alertErr(res.msg)
					}
				});
			},


			// 部门回调信息
			confirm_bumen_cascader_value(value, bumen_items) {
				this.$log('外层 勾选部门id ', value)
				this.$log('外层 勾选部门数据', bumen_items)
				let id_arr = value;
				let departIds = id_arr.join(',')
				let departItems = JSON.stringify(value)
				this.form.depart_id=value[value.length-1]
				this.form.level6=''
				this.form.level6_id=''
				let bumen_titles = bumen_items.map(v => v.title).join('/')
				let bumen_ids = id_arr.join('/')

				this.$log('departIds', departIds)
				this.$log('departItems', departItems)

				this.bumen_position_info = {
					bumen_ids: bumen_ids,
					bumen_titles: bumen_titles
				}



				//岗位列表

				this.$api("getCategoryList", {
					parent_id: id_arr[id_arr.length - 1],
					page: 1,
					limit: 100,
					// ...this.pages,
					// ...this.forms
				}, "get").then((res) => {
					if (res.code == 200) {
						let has_person = res.data.list.some(v => v.is_gw > 0)
						if (has_person) {
							this.count = res.data.count
							this.position_list = res.data.list;
							this.$log('岗位列表', this.position_list)

							this.level6_list = res.data.list
						} else {
							this.count = 0
							this.position_list = [];

							this.level6_list = []
						}
					}
				});


			},


		},



	};
</script>

<style scoped lang="less">
	.Employee_Information_add {
		width: 1640px;
		height: 1743px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #E6E6E6;
		padding: 19px 17px;

		.top_title {
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: bold;
			font-size: 14px;
			color: #333333;
			display: flex;
			align-items: center;
			.title_btns{
				display: flex;
				align-items: center;
				justify-content: center;
				background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;
						margin-left: 16px;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
			}
		}

		.form_flex {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding-top: 57px;

			.form_item {
				width: 50%;
				display: flex;
				align-items: center;
				padding: 9px 0;

				.titles {
					width: 230px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					text-align: right;
					color: #4A4A4A;
					padding-right: 11px;

					span {
						color: #FF0000;
						padding-right: 8px;
					}
				}

				.inputs1 {
					width: 420px;
					height: 40px;
					background: #FFFFFF;
					border-radius: 0px 0px 0px 0px;
					border: 1px solid #E8E8E8;

					input {
						width: 420px;
						height: 40px;
						padding: 0 10px;

						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #747474;

					}
				}

				.inputs2 {
					width: 420px;
					height: 40px;

					display: flex;
					align-items: center;
					justify-content: space-between;

					.check1 {
						width: 116px;
						height: 40px;
						background: #FFFFFF;
					}

					.check2 {
						width: 294px;
						height: 40px;
						background: #FFFFFF;
						border-radius: 0px 0px 0px 0px;
						border: 1px solid #E8E8E8;

						input {
							width: 294px;
							height: 40px;
							padding: 0 10px;

							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}
				}

				.inputs3 {
					width: 420px;
					height: 40px;

					/deep/.el-date-editor {
						width: 420px;
					}

				}

				.inputs4 {
					width: 420px;
					height: 40px;
					display: flex;
					align-items: center;
				}

				.inputs5 {
					width: 420px;
					height: 40px;

					/deep/.el-select {
						width: 100%
					}
				}
			}

			.form_bg {
				background: #F9F9F9;
			}

			.flex_btns {
				width: 100%;
				display: flex;
				align-items: center;
				padding-left: 230px;
				padding-top: 55px;

				.btns1 {
					width: 179px;
					height: 44px;
					background: #2373C8;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					text-align: center;
					line-height: 44px;
					cursor: pointer;
				}

				.btns2 {
					width: 179px;
					height: 44px;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #9D9D9D;
					cursor: pointer;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #4A4A4A;
					text-align: center;
					line-height: 44px;
					margin-left: 18px;
				}
			}


		}
	}
</style>